import React from "react"
import { css } from "@emotion/react"
import { Link } from "gatsby"
const BookListItem = ({ data }) => {
  const { Name, Author, Image } = data
  return (
    <div
      css={css`
        text-align: center;
        cursor: pointer;
      `}
    >
      <Link
        to={`/books/${(Name + " " + Author)
          .toLowerCase()
          .replace(/ /g, "-")
          .replace(/\./g, "")
          .replace(/,/g, "")
          .replace(/[^0-0a-z\-]/g, "")}`}
      >
        <img src={Image}></img>

        <div
          css={css`
            margin-top: 5px;
            color: #323232;
            font-family: "Montserrat", sans-serif;
            font-size: 20px;
            text-decoration: underline;
            @media screen and (max-width: 800px) {
              font-size: 24px;
            }
          `}
        >
          {Name}
        </div>

        <h4
          css={css`
            margin: 0;
            margin-top: 5px;
            color: #323232;
          `}
        >
          {Author}
        </h4>
      </Link>
    </div>
  )
}

export default BookListItem
